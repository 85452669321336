import { Component, useState } from "react";
import logo from './../res/MiniSeries-Logo-e.png';

import "./NavigationBar.css";

export default class NavigationBar extends Component {
  render() {
    return (
      <>
        <nav>
          <div id="left-nav">
            <img onClick={() => this.redirect("#")} src={logo} alt="logo" width={120}></img>
          </div>
          <div id="right-nav">
            <i onClick={() => this.redirect("https://apps.apple.com/us/app/minicord/id1497954327")}>MiniCord</i>
            <i onClick={() => this.redirect("https://apps.apple.com/us/app/miniyt-for-youtube/id1548489132")}>MiniYT</i>
            <i onClick={() => this.redirect("https://apps.apple.com/us/app/miniai-your-ai-assistant/id6446605909")}>MiniAI</i>
            <i onClick={() => this.redirect("https://apps.apple.com/us/app/luna-for-reddit/id1639632308")}>Luna</i>
          </div>
        </nav>
      </>
    );
  }
  redirect(redirect_link) {
    window.location.href = redirect_link;
  }
}
