import NavigationBar from "./Components/NavigationBar";
import HomeView from "./Views/HomeView";
import background from "./res/Home_section.png";
import "./App.css";

function App() {
  return (
    <div
      className="App"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '80vh',
      }}
    >
      <NavigationBar></NavigationBar>
      <HomeView></HomeView>
    </div>
  );
}

export default App;
