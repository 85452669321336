import { Component, useState } from "react";
import logo from './../res/logo.png';
import phoneImg from './../res/iPhone.png';
import background from './../res/Group-1.png';
import "./HomeView.css";

export default class HomeView extends Component {
  render() {
    return (
      <div className="home">
        <div className="left-section">
          <h1>All your favourite apps right</h1>
          <h1>from your wrist!</h1>
          <p style={{marginTop: 15}}>
            MiniSeries have a goal to brign all your favourite apps straight
          </p>
          <p>to your wrist on the Apple watch</p>
          <p style={{marginTop: 30}} id="find-btn" onClick={() => this.redirect("https://discord.gg/R5vFY65")}>Contact us</p>
        </div>
        <div className="right-section">
          <img className="logo-img" src={logo} alt="logo" width={150}></img>
          <img className="phone-img" src={phoneImg} alt="phone"></img>
          <img className="background-img" src={background} alt="bg"></img>
        </div>
      </div>
    );
  }
  redirect(redirect_link) {
    window.location.href = redirect_link;
  }
}
